/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { AjaxService } from './ajax.service';
import { CustomerUserAPIPath, Environment, FloorAPIPath, SerivceTicketAPIPath, SiteAPIPath } from '../constants/api.constant';
import { Utilities } from '../utilities';
import { CommonService } from './common.service';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { BrowserKeys } from '../constants/common.constant';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  constructor(private http: HttpClient, private ajaxService: AjaxService, private readonly env: Environment, private commonService: CommonService, private authService: AuthService) { }
  getAssignedSitesUser(id: any) {
    return this.ajaxService.get(SiteAPIPath.site + '/' + id + '/assignees');
  }
  getSiteDetail(id: number): any {
    return this.ajaxService.get(SiteAPIPath.siteDetails + '/' + id);
  }
  createServiceTickets(floorId: number, data: any): any {
    return this.ajaxService.post(FloorAPIPath.ticketCreate + '/' + floorId + '/serviceticket', data);
  }
  linkTicket(ticketInfo: any) {
    return this.ajaxService.post(SerivceTicketAPIPath.linkTicket, ticketInfo)
  }

  triggeredByMonitoring(flag: any): any {
    return this.ajaxService.put(`${SerivceTicketAPIPath.triggerBy}triggeredByMonitoring=${flag}`, {});
  }

  getServiceTicket(id: number): any {
    return this.ajaxService.get(`${SerivceTicketAPIPath.ticket}/${id}`);
  }

  getServiceTickets(data: any): any {
    return this.ajaxService.post(SerivceTicketAPIPath.tickets, data);
  }

  getActiveUsers(): any {
    return this.ajaxService.get(`${CustomerUserAPIPath.activeUsers}`);
  }

  getUsers(): any {
    return this.ajaxService.get(CustomerUserAPIPath.customerSpecificUsers);
  }

  deleteFileFromTicket(id: any, fileId: any) {
    return this.ajaxService.delete(`${SerivceTicketAPIPath.file}/${id}/file/${fileId}`);
  }
  getTicketFilePresignedURL(reqData: any) {
    return this.ajaxService.get(SerivceTicketAPIPath.presigned + Utilities.objectToParams(reqData));
  }

  uploadFile(url: string, formData: any) {
    let uploadContentType = 'multipart/form-data';
    if (this.env.localstack) {
      uploadContentType = 'undefined';
    }
    return this.ajaxService.put(url, formData, { headers: { 'Content-Type': uploadContentType } }, { removeAuthToken: true });
  }

  saveTicketFile(tickeId: any, files: any) {
    return this.ajaxService.post(SerivceTicketAPIPath.file + '/' + tickeId + '/files', files);
  }

  unlinkTicket(ticketId: any, floorEquipmentId: any, serviceTicketFloorEquipmentId: any) {
    return this.ajaxService.delete(`${SerivceTicketAPIPath.ticket}/${ticketId}/floorequipment/${floorEquipmentId}/${serviceTicketFloorEquipmentId}`);
  }
  archiveTicket(id: any, isArchive: any) {
    return this.ajaxService.get(`${SerivceTicketAPIPath.ticket}/${id}/archived?isArchived=` + isArchive);
  }
  deleteServiceTicket(id: number): any {
    return this.ajaxService.delete(`${SerivceTicketAPIPath.ticket}/${id}`);
  }

  // For Quotes
  createOrUpdateQuote(ticketId: any, data: any): any {
    return this.ajaxService.post(SerivceTicketAPIPath.InlineTicket + '/' + ticketId + '/quote', data);
  }

  createItemQuote(ticketId: any, data: any): any {
    return this.ajaxService.post(SerivceTicketAPIPath.InlineTicket + '/' + ticketId + '/quote/item', data);
  }

  updateItemQuote(ticketId: any, data: any): any {
    return this.ajaxService.put(SerivceTicketAPIPath.InlineTicket + '/' + ticketId + '/quote/item', data);
  }

  deleteItemQuote(id: number): any {
    return this.ajaxService.delete(`${SerivceTicketAPIPath.quoteDelete}/${id}`);
  }

  generatePDF(id: number): any {
    const data = 'Bearer ' + this.commonService.getLocalStorageString(BrowserKeys.token);
    const httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': data,
        'customerId': this.authService.getCustomerId().customerId.toString()
      }),
      reportProgress: true,
    };

    httpOptions['responseType'] = 'text'
    const req = new HttpRequest('POST', `${this.env.apiPath}${SerivceTicketAPIPath.print_pdf}/print_pdf/${id}`, null, httpOptions);
    return this.http.request(req);
  }

  updateServiceTickets(ticket: any) {
    return this.ajaxService.put(SerivceTicketAPIPath.ticket + '/' + ticket.id, ticket);
  }

  checkCopyLinkTicketAcess(ticketId: any, customerId: any) {
    return this.ajaxService.get(`${SerivceTicketAPIPath.ticket}/${ticketId}/access?customerId=` + customerId);
  }

  getUser(id: number): any {
    return this.ajaxService.get(CustomerUserAPIPath.user + '/' + id);
  }
  removeComment(id: any) {
    return this.ajaxService.delete(`${SerivceTicketAPIPath.ticket}/comment/${id}`);
  }
  editComment(ticketId: any, comment: any) {
    return this.ajaxService.put(`${SerivceTicketAPIPath.ticket}/${ticketId}/comment`, comment);
  }

  addComment(item: any, id: any) {
    return this.ajaxService.post(`${SerivceTicketAPIPath.ticket}/${id}/comment`, item);
  }
}
